import { useState } from "react";
import { Document, Page } from "react-pdf";
import { Button } from "@mui/material";
import { pdfjs } from "react-pdf";
import chairs from "./images/chairs.jpg";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

function PDFViewer({
  fileData,
  onFileChange,
  onlyDisplayMode = false,
  hide = true,
}) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      className="pdf-container"
      style={{
        display: hide ? "none" : "",
      }}
    >
      {fileData ? (
        <>
          <Document
            file={fileData}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            renderMode="canvas"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={1.5}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
        </>
      ) : !onlyDisplayMode ? (
        <div className="empty-pdf-container">
          <img src={chairs} alt="chairs" style={{ width: "250px" }} />
          <div style={{ paddingBottom: 15 }}>
            No active application currently
          </div>
          <Button variant="contained" component="label">
            Click here to upload application
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={(e) => onFileChange(e, true)}
            />
          </Button>
        </div>
      ) : (
        <p>No file chosen to display</p>
      )}
    </div>
  );
}

export default PDFViewer;
