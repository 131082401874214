import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

export default function Notes({ candidateId }) {
  const [note, setNote] = useState(null);
  const [cookies] = useCookies();

  useEffect(() => {
    axios
      .get(`/note/${candidateId}`, {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((resp) => {
        setNote(resp.data?.note);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (note === null) return;
      axios
        .post(
          "/note",
          {
            candidate_id: candidateId,
            note: note,
          },
          {
            headers: {
              Authorization: `bearer ${cookies.token}`,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);

    return () => {
      clearTimeout(debounceTimer);
    };
  });

  function handleNoteChange(event) {
    setNote(event.target.value);
  }

  return (
    <div>
      <TextField
        id="outlined-multiline-static"
        multiline
        rows={4}
        onChange={handleNoteChange}
        value={note}
        placeholder="Notes"
        defaultValue=""
        style={{
          margin: "5%",
          width: "90%",
        }}
      />
    </div>
  );
}
