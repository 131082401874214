import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, useLocation } from "react-router-dom";

const drawerWidth = 240;

const Sidebar = () => {
  let location = useLocation();

  console.log(location);
  return (
    <div
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <List>
        <Link to="llm" style={{ textDecoration: "none" }}>
          <ListItemButton selected={location.pathname === "/settings/llm"}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="LLM Configuration" />
          </ListItemButton>
        </Link>
        <Link to="form" style={{ textDecoration: "none" }}>
          <ListItemButton selected={location.pathname === "/settings/form"}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Form Configuration" />
          </ListItemButton>
        </Link>
      </List>
    </div>
  );
};

export default Sidebar;
