import React from "react";
import Sidebar from "./SettingsSidebar";
import { Box } from "@mui/material";
import NavigationBar from "../../components/NavigationBar";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <>
      <NavigationBar />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default App;
