import { Card } from "@mui/material";

let structure = [
  {
    title: "Class X",
    id: "class_x_comment",
  },
  {
    title: "Class XII",
    id: "class_xii_comment",
  },
  {
    title: "Survey",
    id: "survey_section_comment",
  },
  {
    title: "Essay",
    id: "essays_section_comment",
  },
  {
    title: "Support",
    id: "support_section_comment",
  },
  {
    title: "Final ECA",
    id: "final_eca_comment",
  },
  {
    title: "Final Comments",
    id: "final_comments",
  },
];

export default function ReaderComments({ readerComments }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {structure.map((item) => (
        <Card style={{ margin: "14px", padding: "10px", width: "40%" }}>
          <h4 style={{ margin: "0px" }}>{item.title}</h4>
          <p style={{ margin: "0px" }}>{readerComments[item.id]}</p>
        </Card>
      ))}
    </div>
  );
}
