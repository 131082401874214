import { Grid, Button, FormHelperText } from "@mui/material";
import * as Styled from "./Auth.styled";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const LOGIN = "LOGIN";
const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const EMAIL_SENT = "EMAIL_SENT";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token"]);
  const [error, setError] = useState("");
  const [authPage, setAuthPage] = useState(LOGIN);

  useEffect(() => {
    if (cookies.token) {
      navigate("/");
    }
  }, [cookies.token, navigate]);

  function authenticate(e) {
    e.preventDefault();
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setError("Invalid email");
      return;
    }
    if (!password) {
      setError("Enter password");
      return;
    }
    axios
      .post("/signin", {
        email,
        password,
      })
      .then((resp) => {
        setCookie("token", resp.data.access_token);
        navigate("/");
      })
      .catch((err) => {
        setError(err?.response?.data?.detail || "Something went wrong");
      });
  }

  function sendAuthEmail() {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setError("Invalid email");
      return;
    }
    axios
      .post("/request_password_reset", {
        email: email,
      })
      .then((resp) => {
        setAuthPage(EMAIL_SENT);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (authPage === EMAIL_SENT) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", backgroundColor: "#fa7fc" }}
      >
        <div>
          Your password reset email has been sent. Kindly check email for more
          details.
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", backgroundColor: "#fa7fc" }}
    >
      <Styled.Heading>Welcome to Wisdom.AI</Styled.Heading>
      <Styled.Subheading>
        The platform that helps you interview high-school applicants with
        confidence
      </Styled.Subheading>
      <Styled.Form onSubmit={authPage === LOGIN ? authenticate : sendAuthEmail}>
        <Styled.InputWrapper>
          <Styled.InputBox>
            <Styled.InputTitle>Email</Styled.InputTitle>
            <Styled.Input
              name="email"
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
              }}
              value={email}
              variant="filled"
              label="email"
            />
          </Styled.InputBox>
          {authPage === LOGIN && (
            <Styled.InputBox>
              <Styled.InputTitle>Password</Styled.InputTitle>
              <Styled.Input
                onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
                }}
                value={password}
                label="password"
                name="password"
                type="password"
                variant="filled"
                autoComplete="current-password"
              />
            </Styled.InputBox>
          )}
        </Styled.InputWrapper>
        <FormHelperText style={{ margin: "10px" }} error>
          {error}
        </FormHelperText>
        {authPage === LOGIN && (
          <Styled.ForgotPassword
            onClick={() => {
              setError("");
              setAuthPage(FORGOT_PASSWORD);
            }}
          >
            Forgot your password?
          </Styled.ForgotPassword>
        )}
        {authPage === FORGOT_PASSWORD && (
          <Styled.ForgotPassword
            onClick={() => {
              setError("");
              setAuthPage(LOGIN);
            }}
          >
            Login
          </Styled.ForgotPassword>
        )}
        <Button
          style={{ width: "100%", backgroundColor: "#801AE5", margin: "10px" }}
          variant="contained"
          type="submit"
          disableElevation
        >
          {authPage === LOGIN ? "Login" : "Reset Password"}
        </Button>
      </Styled.Form>
    </Grid>
  );
}
