import React from "react";
import { useEffect, useState } from "react";
import { Tab, Tabs, Box, Drawer } from "@mui/material";
import UserList from "./components/UserList";
import PDFViewer from "./components/PDFViewer";
import TabPanel from "./components/TabPanel";
import axios from "axios";
import _ from "lodash";

import TagStack from "./components/TagStack";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { HourglassBottom } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { calculateAge } from "./utils";
import ScoringPanel from "./components/scoringPanel";
import NavigationBar from "../../components/NavigationBar";
import { useCookies } from "react-cookie";

const CVReviewer = () => {
  const [users, setUsers] = useState([]);
  const [areFetchingUsers, setAreFetchingUsers] = useState(true);
  const [currFile, setCurrFile] = useState(null);
  const [currCandidate, setCurrCandidate] = useState(null);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [currFileIndex, setCurrFileIndex] = useState(0);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [cookies] = useCookies();

  useEffect(() => {
    setAreFetchingUsers(true);
    axios
      .get("/candidates/", {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
        setAreFetchingUsers(false);
      })
      .catch((error) => {
        setAreFetchingUsers(false);
      });
  }, [cookies.token]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage("");
    setSeverity("");
  };

  const renderSnackBar = (message, severity) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  };

  const addRemoveCvToResourceFiles = (
    file,
    resetState = false,
    append = false
  ) => {
    let currResourceFiles = [...resourceFiles];
    currResourceFiles = resetState
      ? []
      : currResourceFiles.filter((f) => f !== file);
    if (append) {
      setResourceFiles([...currResourceFiles, file]);
    }
  };

  const fetchFile = (user) => {
    setCurrCandidate(user);
    setIsFileUploading(true);
    axios({
      method: "get",
      url: `/candidates/${user.id}/resume`,
      responseType: "blob",
      headers: {
        Authorization: `bearer ${cookies.token}`,
      },
    })
      .then((response) => {
        console.log("File fetched");
        const fileURL = URL.createObjectURL(response.data);
        setCurrFile(fileURL);
        addRemoveCvToResourceFiles(fileURL, true, true);
        console.log("CurrFile URL: ", fileURL);
        setIsFileUploading(false);
      })
      .catch((error) => {
        console.error("Error fetching file: ", error);
        setIsFileUploading(false);
      });
  };

  const onFileChange = async (event, isResume = false) => {
    const file = event.target.files[0];
    if (file) {
      if (isResume) {
        const fileURL = URL.createObjectURL(file);
        setCurrFile(fileURL);
        uploadFile(file);
        setCurrCandidate(null);
        // setResourceFiles([]);
        addRemoveCvToResourceFiles(fileURL, true, true);
      }
    }
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setIsFileUploading(true);
    axios
      .post("/candidates/", formData, {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((data) => {
        setIsFileUploading(false);
        setCurrCandidate(data.data);
        setUsers([...users, data.data]);
      })
      .catch((error) => {
        setIsFileUploading(false);
        console.error("Error:", error);
      });
  };

  const updateQuestionnaire = (questionnaire) => {
    setCurrCandidate((prev) => ({
      ...prev,
      questionnaire,
    }));
  };

  const renderUserTags = () => {
    return currCandidate ? (
      <TagStack tags={_.get(currCandidate, "tags")} />
    ) : null;
  };

  const handlePdfTabChange = (event, tabIdx) => {
    setCurrFileIndex(tabIdx);
  };

  const renderFiles = () =>
    resourceFiles.map((file, index) => (
      <PDFViewer
        currUser={currCandidate}
        fileData={resourceFiles[currFileIndex]}
        setFileData={setCurrFile}
        onFileChange={onFileChange}
        onlyDisplayMode={currFileIndex > 0}
        hide={currFileIndex !== index}
      />
    ));

  const setRemarks = (remarks) => {
    // axios.post('http://localhost:80/candidates/update-remarks/', {
    //     candidate_id: _.get(currCandidate, 'id'),
    //     remarks
    // })
    // .then(response => {
    //     console.log('Remarks updated successfully');
    //     setCurrCandidate(prev => ({
    //         ...prev,
    //         remarks
    //     }));
    //     renderSnackBar('Remarks updated successfully', 'success');
    // })
    // .catch(error => {
    //     console.error('Error updating remarks:', error);
    //     renderSnackBar('Error updating remarks', 'error');
    // });
    console.log(remarks);
  };

  return (
    <>
      <NavigationBar
        sideDrawerOpen={sideDrawerOpen}
        setSideDrawerOpen={setSideDrawerOpen}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Drawer open={sideDrawerOpen} onClose={() => setSideDrawerOpen(false)}>
        <Box sx={{ width: "30vw", maxWidth: "300px" }}>
          <UserList
            isLoading={areFetchingUsers}
            users={users}
            onFileChange={onFileChange}
            currUser={currCandidate}
            onSelectUser={(user) => {
              fetchFile(user);
            }}
          />
        </Box>
      </Drawer>
      <Box display="flex">
        <Box width="75%">
          <div className="pdf-header">
            {isFileUploading ? (
              <div className="recommendations-container">
                <HourglassBottom />
                <p>Uploading & Analysing Candidate's Resume</p>
              </div>
            ) : (
              <>
                <h3>
                  {_.get(currCandidate, "first_name")}{" "}
                  {_.get(currCandidate, "last_name")}{" "}
                  <span className="user-age">
                    {currCandidate
                      ? `, ${calculateAge(
                          _.get(currCandidate, "date_of_birth")
                        )} Years old`
                      : ""}
                  </span>
                </h3>
                <div className="tags-container">{renderUserTags()}</div>
              </>
            )}
          </div>
          {resourceFiles.length <= 1 && (
            <PDFViewer
              currUser={currCandidate}
              fileData={currFile}
              setFileData={setCurrFile}
              onFileChange={onFileChange}
              hide={false}
            />
          )}
          {currFile && resourceFiles.length > 1 && (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  onChange={handlePdfTabChange}
                  aria-label="basic tabs example"
                >
                  {resourceFiles.map((file, index) => (
                    <Tab
                      key={index}
                      label={index > 0 ? `File ${index + 1}` : "Form"}
                    />
                  ))}
                </Tabs>
              </Box>
              {renderFiles()}
            </>
          )}
        </Box>
        <Box
          width="25%"
          height="calc(100vh - 64px)"
          sx={{
            borderLeft: "1px solid black",
          }}
        >
          {isFileUploading ? (
            <div className="recommendations-container">
              <HourglassBottom />
              <p>Uploading & Analysing Candidate's Resume</p>
            </div>
          ) : currCandidate ? (
            <TabPanel
              promptFilters={_.get(currCandidate, "prompt_filters", [])}
              candidateId={_.get(currCandidate, "id")}
              questionnaire={_.get(currCandidate, "questionnaire", [])}
              setQuestionnaire={updateQuestionnaire}
              genQuestions={_.get(currCandidate, "gen_q", {
                essay_: [],
                book_: [],
                activity_: [],
              })}
              setResourceFiles={setResourceFiles}
              remarks={_.get(currCandidate, "remarks", {})}
              setRemarks={setRemarks}
            />
          ) : (
            <div className="recommendations-container">
              <AutoAwesomeIcon fontSize="30" />
              <p>Select / Add a Candidate to view Ai suggestions</p>
            </div>
          )}
        </Box>
      </Box>
      <ScoringPanel currCandidate={currCandidate} />
    </>
  );
};

export default CVReviewer;
