import React from 'react';
import { TextField, Checkbox, Button, Select, MenuItem, FormControl, InputLabel, FormGroup } from '@mui/material';

const FormDisplay = ({ fields, saveRemarks }) => {
  const [formData, setFormData] = React.useState({});

  const handleChange = (id, value) => {
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const checkCondition = (field) => {
    const { condition } = field;
    if (!condition || !condition.field) return true;

    const targetValue = formData[condition.field];
    const conditionValue = condition.value;

    switch (condition.type) {
      case 'equalTo':
        return targetValue == conditionValue;
      case 'greaterThan':
        return parseFloat(targetValue) > parseFloat(conditionValue);
      case 'lesserThan':
        return parseFloat(targetValue) < parseFloat(conditionValue);
      default:
        return true;
    }
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); saveRemarks(formData); }}>
      {fields.map((field, index) => {
        if (!checkCondition(field)) return null;

        switch (field.type) {
          case 'text':
            return (
              <TextField
                key={index}
                label={field.label}
                required={field.required}
                value={formData[field.id] || ''}
                onChange={(e) => handleChange(field.id, e.target.value)}
                margin="dense"
                fullWidth
              />
            );
          case 'textarea':
            return (
              <TextField
                key={index}
                label={field.label}
                required={field.required}
                value={formData[field.id] || ''}
                onChange={(e) => handleChange(field.id, e.target.value)}
                margin="dense"
                fullWidth
                multiline
                rows={4}
              />
            );
          case 'number':
            return (
              <TextField
                key={index}
                label={field.label}
                required={field.required}
                type="number"
                value={formData[field.id] || ''}
                onChange={(e) => handleChange(field.id, e.target.value)}
                margin="dense"
                fullWidth
              />
            );
          case 'select':
            return (
              <FormControl key={index} fullWidth margin="dense">
                <InputLabel>{field.label}</InputLabel>
                <Select
                  value={formData[field.id] || ''}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  required={field.required}
                >
                  {field.options.map((option, idx) => (
                    <MenuItem key={idx} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          case 'multiselect':
            return (
              <FormControl key={index} fullWidth margin="dense">
                <InputLabel>{field.label}</InputLabel>
                <Select
                  multiple
                  value={formData[field.id] || []}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  required={field.required}
                >
                  {field.options.map((option, idx) => (
                    <MenuItem key={idx} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          default:
            return null;
        }
      })}
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>Save</Button>
    </form>
  );
};

export default FormDisplay;
