import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import BasicMenu from "./Menu";

function NavigationBar({
  sideDrawerOpen = () => {},
  setSideDrawerOpen = () => {},
}) {
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuIcon onClick={() => setSideDrawerOpen(!sideDrawerOpen)} />
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <img
              src="/logo.png"
              alt="logo"
              style={{ width: 45, height: 45, paddingLeft: 20 }}
            />
          </Link>
        </div>
        <Typography style={{ color: "white" }}>
          <span style={{ fontWeight: "bold" }}>Wisdom</span>@Ashoka
        </Typography>
        <BasicMenu />
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
