import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { useCookies } from "react-cookie";

const LLMConfiguration = () => {
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const [cookies] = useCookies();

  useEffect(() => {
    setLoading(true);
    axios
      .get("/llms/config", {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((data) => {
        setConfigData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching LLM configuration:", error);
        setLoading(false);
      });
  }, []);

  const handleProviderChange = (event) => {
    setSelectedProvider(event.target.value);
    setSelectedModel("");
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage("");
    setSeverity("");
  };

  const renderSnackBar = (message, severity) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  };

  const handleSubmit = () => {
    console.log("Selected Model:", selectedModel);

    axios
      .post("/llms/update-model", { model: selectedModel })
      .then((response) => {
        console.log("Model updated successfully");
        renderSnackBar("Model updated successfully", "success");
      })
      .catch((error) => {
        console.error("Error updating model:", error);
        renderSnackBar("Error updating model", "error");
      });
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <Typography variant="h4" gutterBottom>
            LLM Configuration
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="provider-label">Provider</InputLabel>
            <Select
              labelId="provider-label"
              value={selectedProvider}
              label="Provider"
              onChange={handleProviderChange}
            >
              {configData &&
                configData?.map((provider) => (
                  <MenuItem key={provider.model} value={provider.model}>
                    {provider.model}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {selectedProvider && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="model-label">Model</InputLabel>
              <Select
                labelId="model-label"
                value={selectedModel}
                label="Model"
                onChange={handleModelChange}
              >
                {configData
                  .find((p) => p.model === selectedProvider)
                  ?.choices.map((choice) => (
                    <MenuItem
                      key={choice.value}
                      value={choice.value}
                      disabled={!choice.enabled}
                    >
                      {choice.value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {selectedModel && (
            <Typography style={{ marginTop: 20 }}>
              Selected Model: {selectedModel}
            </Typography>
          )}

          <div className="submit-button">
            <Button
              disabled={!selectedModel}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update Model
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default LLMConfiguration;
