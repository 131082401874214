import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useCookies } from "react-cookie";

export default function RequireAuth({ children }) {
  const [cookies] = useCookies(["token"]);
  const location = useLocation();
  if (!cookies.token) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
