import { createTheme } from "@mui/material/styles";

const colors = {
  oxfordBlue: "#0a1128",
  pennBlue: "#001f54",
  indigoDye: "#034078",
  cerulean: "#1282a2",
  sunglow: "#ffcb47",
};

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans"],
  },
  // palette: {
  //   primary: {
  //     main: colors.sunglow,
  //     contrastText: colors.oxfordBlue,
  //   },
  //   secondary: {
  //     main: colors.cerulean,
  //     contrastText: "#ffffff",
  //   },
  //   background: {
  //     default: colors.oxfordBlue,
  //     paper: colors.pennBlue,
  //   },
  //   error: {
  //     main: "#d32f2f",
  //   },
  //   warning: {
  //     main: "#ffa726",
  //   },
  //   info: {
  //     main: colors.indigoDye,
  //   },
  //   success: {
  //     main: "#2e7d32",
  //   },
  // },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: colors.indigoDye,
          "&:hover": {
            backgroundColor: colors.pennBlue,
          },
        },
      },
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: colors.pennBlue,
    //       color: "#ffffff",
    //     },
    //     indicator: {
    //       backgroundColor: colors.sunglow,
    //     },
    //   },
    // },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: colors.sunglow,
          },
        },
      },
    },
    // MuiAppBar: {
    //   styleOverrides: {
    //     colorPrimary: {
    //       backgroundColor: colors.oxfordBlue,
    //     },
    //   },
    // },
    MuiInput: {
      styleOverrides: {
        root: {
          color: colors.oxfordBlue,
          "&::before": {
            borderColor: colors.indigoDye,
          },
          "&:hover:not(.Mui-disabled)::before": {
            borderColor: colors.cerulean,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.oxfordBlue,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: colors.oxfordBlue,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: colors.oxfordBlue, // Sets the text color for the dropdown items
          "&:focus": {
            backgroundColor: "transparent", // Keeps background transparent on focus
          },
        },
        icon: {
          color: colors.oxfordBlue, // Sets the dropdown arrow icon color
        },
      },
    },
  },
});

export { theme, colors };
