import React, { useState, useEffect } from "react";
import axios from "axios";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Psychology } from "@mui/icons-material";
import Notes from "./Notes";

function QuestionsList({ questionnaire = [], candidateId }) {
  return (
    <div>
      <Notes candidateId={candidateId} />
      <List className="questionnaire-list">
        {questionnaire.map((question, index) => (
          <Box
            key={Math.random() * Math.random() * Math.random()}
            sx={{
              padding: 2,
              marginBottom: 1,
              cursor: "pointer",
              fontSize: "12px",
            }}
            className="question-container"
          >
            <span>
              {question.question}
              {/* <span style={{ float: 'right' }}>-</span> */}
            </span>
            <div
              style={{
                fontSize: "12px",
                color: "gray",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Psychology /> {question.context}
            </div>
          </Box>
        ))}
      </List>
    </div>
  );
}

export default QuestionsList;
