import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { theme } from "./config/themeConfig";
import { ThemeProvider } from "@mui/material/styles";
import CVReviewer from "./screens/cvReviewer/cvReviewer";
import FormCreator from "./screens/Settings/formCreator";
import Settings from "./screens/Settings";

import "./screens/cvReviewer/cvReviewer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "./screens/Settings/Settings.css";
import Login from "./screens/auth";
import RequireAuth from "./components/RequireAuth";
import LLMConfiguration from "./screens/Settings/LLMConfiguration";
import FormConfiguration from "./screens/Settings/FormConfiguration";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <CVReviewer />
              </RequireAuth>
            }
          />
          <Route path="/form-creator" element={<FormCreator />} />
          <Route path="/auth" element={<Login />} />
          <Route path="/settings" element={<Settings />}>
            <Route path="llm" element={<LLMConfiguration />} />
            <Route path="form" element={<FormConfiguration />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
