import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  IconButton,
} from "@mui/material";
import { useCookies } from "react-cookie";
import {
  ArrowForward,
  AddCircle,
  PeopleOutline,
  CloudDownload,
} from "@mui/icons-material";

import _ from "lodash";
import axios from "axios";

const downloadFile = (response, filename = "downloaded_file") => {
  // Create a Blob from the response data
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  // Create an object URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary <a> element and trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); // Set the filename for the download
  document.body.appendChild(link); // Required for Firefox
  link.click();

  // Clean up by revoking the object URL and removing the <a> element
  window.URL.revokeObjectURL(url);
  link.parentNode.removeChild(link);
};

function UserList({ users, onSelectUser, isLoading, onFileChange, currUser }) {
  const [cookies] = useCookies();

  const downloadRemarks = () => {
    axios
      .get("/candidates/download-remarks/", {
        responseType: "blob",
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((response) => {
        downloadFile(response, "remarks.xlsx");
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {/* button that has a + sign with Add User showing up */}
      {/* {currUser && <div className='add-user-container'>
      <Button variant="contained" component='label'>
        Add User
        <input
          type="file"
          hidden
          accept="application/pdf"
          onChange={(e) => onFileChange(e, true)}
        />
      </Button>
    </div>} */}
      <div className="add-user-container">
        <Button variant="contained" component="label">
          Add User
          <input
            type="file"
            hidden
            accept="application/pdf"
            onChange={(e) => onFileChange(e, true)}
          />
        </Button>
        <IconButton onClick={downloadRemarks}>
          <CloudDownload />
        </IconButton>
      </div>
      <List className="users-list-container">
        {isLoading && <ListItem>Loading...</ListItem>}
        {!isLoading &&
          _.isArray(users) &&
          users.map((user, index) => (
            <ListItem key={index} button onClick={() => onSelectUser(user)}>
              <ListItemAvatar>
                <Avatar>
                  {_.get(user, "first_name")[0] + _.get(user, "last_name")[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${_.get(user, "first_name")} ${_.get(
                  user,
                  "last_name"
                )}`}
              />
              <Button variant="contained">
                <ArrowForward />
              </Button>
            </ListItem>
          ))}
      </List>
      {_.get(users, "length") === 0 && (
        <div className="empty-users-container">
          <PeopleOutline />
          <p>Looks like you haven't added any Candidates !</p>
        </div>
      )}
    </>
  );
}

export default UserList;
