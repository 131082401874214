import React, { useState, useEffect } from "react";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";
import MenuBook from "@mui/icons-material/MenuBook";
import Tooltip from "@mui/material/Tooltip";
import RowingIcon from "@mui/icons-material/Rowing";
import HourglassBottom from "@mui/icons-material/HourglassBottom";
import PsychologyIcon from "@mui/icons-material/Psychology";
import Box from "@mui/material/Box";
import _ from "lodash";
import { Psychology } from "@mui/icons-material";
import { useCookies } from "react-cookie";

function FilterTabs({
  promptFilters,
  candidateId,
  setQuestionnaire,
  questionnaire,
  genQuestions,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [questionsCache, setQuestionsCache] = useState(genQuestions);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionsMap, setSelectedQuestionsMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [cookies] = useCookies();

  const icons = {
    essay_: SubjectIcon,
    book_: MenuBook,
    activity_: RowingIcon,
  };

  const fetchQuestionsIfNeeded = (force = false, sameTab = false) => {
    const filter = promptFilters[activeTab];
    if (!sameTab) {
      setQuestions([]);
    }
    setIsLoading(true);
    if (_.isEmpty(questionsCache[filter]) || force) {
      axios
        .post(
          `/generate-questions/`,
          {
            candidate_id: candidateId,
            prompt_filter: filter,
          },
          {
            headers: {
              Authorization: `bearer ${cookies.token}`,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setQuestionsCache((prev) => ({
            ...prev,
            [filter]: [...prev[filter], ...response.data.questions],
          }));
          // TODO(bug): this is causing new questions to come in the wrong place...
          setQuestions((prev) => [...prev, ...response.data.questions]);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Failed to fetch questions:", error);
        });
    } else {
      setIsLoading(false);
      setQuestions(questionsCache[filter]);
    }
  };

  useEffect(() => {
    fetchQuestionsIfNeeded();

    setSelectedQuestionsMap(
      questionnaire.reduce((acc, question) => {
        acc[question.question] = true;
        return acc;
      }, {})
    );
  }, [
    activeTab,
    promptFilters,
    candidateId,
    questionnaire,
    genQuestions,
    questionsCache,
  ]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleQuestionSelect = (question) => {
    const questionText = question.question;
    if (selectedQuestionsMap[questionText]) {
      axios
        .post(
          `/candidates/remove-question/`,
          {
            candidate_id: candidateId,
            question: questionText,
            context: question.context,
          },
          {
            headers: {
              Authorization: `bearer ${cookies.token}`,
            },
          }
        )
        .then((response) => {
          setQuestionnaire(
            questionnaire.filter((q) => q.question !== questionText)
          );
        })
        .catch((error) => {
          console.error("Failed to remove question:", error);
        });
    } else {
      let module_tag;
      if (activeTab === 0) {
        module_tag = "book";
      } else if (activeTab === 1) {
        module_tag = "essay";
      } else if (activeTab === 2) {
        module_tag = "activity";
      }
      axios
        .post(
          `/candidates/add-question/`,
          {
            candidate_id: candidateId,
            question: questionText,
            context: question.context,
            module_tag: module_tag,
            type: "system",
          },
          {
            headers: {
              Authorization: `bearer ${cookies.token}`,
            },
          }
        )
        .then((response) => {
          setQuestionnaire([...questionnaire, question]);
        })
        .catch((error) => {
          console.error("Failed to add question:", error);
        });
    }

    setSelectedQuestionsMap((prev) => ({
      ...prev,
      [questionText]: !prev[questionText],
    }));
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="icon label tabs"
      >
        {promptFilters.map((filter, index) => {
          const Icon = icons[filter];
          return (
            <Tooltip title={filter.slice(0, -1).toUpperCase()} key={filter}>
              <Tab icon={<Icon />} />
            </Tooltip>
          );
        })}
      </Tabs>
      <Box sx={{ marginTop: 2 }} className="suggestion-questions-container">
        {questions.map((question) => (
          <Box
            key={Math.random() * Math.random() * Math.random()}
            sx={{
              padding: 2,
              marginBottom: 1,
              backgroundColor: selectedQuestionsMap[question.question]
                ? "lightblue"
                : "transparent",
              cursor: "pointer",
            }}
            className="question-container"
            onClick={() => handleQuestionSelect(question)}
          >
            <span>
              {question.question} <span style={{ float: "right" }}>+</span>
            </span>
            <div
              style={{
                fontSize: "12px",
                color: "gray",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Psychology /> {question.context}
            </div>
          </Box>
        ))}
        {!_.isEmpty(genQuestions) ? (
          <div className="gen-questions-btn-container">
            <Button
              disabled={isLoading}
              style={{ color: "white" }}
              onClick={() => fetchQuestionsIfNeeded(true, true)}
            >
              {isLoading ? (
                <>
                  <HourglassBottom /> Loading
                </>
              ) : (
                "Generate More Questions"
              )}
            </Button>
          </div>
        ) : null}
      </Box>
    </div>
  );
}

export default FilterTabs;
