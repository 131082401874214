import * as React from "react";
import Typography from "@mui/material/Typography";
import * as Styled from "./scoringPanelStyled";
import {
  Checkbox,
  FormHelperText,
  MenuItem,
  Rating,
  Select,
  TextField,
} from "@mui/material";
import LabTabs from "./textboxTab";

function IconContainer(props) {
  const { value, ...other } = props;
  return (
    <span
      {...other}
      style={{
        border: "1px solid",
        borderRadius: "20px",
        margin: "1px",
        width: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "15px",
      }}
    >
      {value}
    </span>
  );
}

function FormBuilder({ item, values, editFormValue }) {
  if (item.type === "rating") {
    return (
      <Styled.RatingWrapper key={item.id}>
        <Typography component="legend">{item.name}</Typography>
        <Rating
          name={item.id}
          disabled={values.submitted}
          value={values[item.id]}
          IconContainerComponent={IconContainer}
          onChange={(event, newValue) => editFormValue(item.id, newValue)}
        />
      </Styled.RatingWrapper>
    );
  } else if (item.type === "textbox") {
    return (
      <Styled.TextBoxWrapper key={item.id}>
        <TextField
          id="outlined-multiline-static"
          label={item.name}
          multiline
          disabled={values.submitted}
          value={values[item.id]}
          onChange={(e) => editFormValue(item.id, e.target.value)}
          style={{ width: "95%" }}
          rows={4}
        />
      </Styled.TextBoxWrapper>
    );
  } else if (item.type === "dropdown") {
    return (
      <div key={item.id} style={{ paddingTop: "10px" }}>
        <FormHelperText>{item.name}</FormHelperText>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          disabled={values.submitted}
          value={values[item.id]}
          label="Age"
          style={{ width: "100%" }}
          onChange={(e) => editFormValue(item.id, e.target.value)}
        >
          {item.menuItems.map((menuItem) => (
            <MenuItem value={menuItem} key={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  } else if (item.type === "multicheckbox") {
    return (
      <div key={item.id} style={{ padding: "12px 7px" }}>
        {item.tags.map((tag) => (
          <span key={tag} style={{ display: "inline-block" }}>
            <Checkbox
              checked={values[item.id].includes(tag)}
              disabled={values.submitted}
              onChange={(e) => {
                let index = values[item.id].indexOf(tag);
                let newArr = [...values[item.id]];
                if (index < 0) {
                  newArr.push(tag);
                } else {
                  newArr.splice(index, 1);
                }
                editFormValue(item.id, newArr);
              }}
            />{" "}
            <span>{tag}</span>
          </span>
        ))}
      </div>
    );
  } else if (item.type === "textboxtabs") {
    return (
      <div>
        <LabTabs
          tabs={item.textboxes}
          values={values}
          editFormValue={editFormValue}
        />
      </div>
    );
  }
}

export default FormBuilder;
