import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TextField } from "@mui/material";

export default function LabTabs({ tabs, values, editFormValue }) {
  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabs.map((tab, index) => (
              <Tab key={tab.id} label={tab.name} value={index.toString()} />
            ))}
          </TabList>
        </Box>

        {tabs.map((tab, index) => (
          <TabPanel value={index.toString()} key={tab.id}>
            <TextField
              id="outlined-multiline-static"
              label={tab.name}
              disabled={values.submitted}
              multiline
              value={values[tab.id]}
              onChange={(e) => editFormValue(tab.id, e.target.value)}
              style={{ width: "95%" }}
              rows={4}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
