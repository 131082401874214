import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as Styled from "./scoringPanelStyled";
import { Button, Card, Tab } from "@mui/material";
import FormBuilder from "./formBuilder";
import DialogBox from "./dialogBox";
import axios from "axios";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ReaderComments from "./readerComments";
import Toast from "../../../../components/Toast";

const drawerBleeding = 56;

const shape = {
  left: [
    {
      type: "rating",
      name: "Intellectual curiosity",
      id: "intellectualCuriosity",
    },
    {
      type: "rating",
      name: "Self-awareness",
      id: "selfAwareness",
    },
    {
      type: "rating",
      name: "Drive/Excellence",
      id: "drive",
    },
    {
      type: "rating",
      name: "Impact",
      id: "impact",
    },
    {
      type: "dropdown",
      name: "Status",
      id: "status",
      menuItems: [
        "Not Recommended",
        "Has Potential",
        "Recommended",
        "Unable to decide",
      ],
    },
  ],
  right: [
    {
      type: "textbox",
      name: "Final Comments",
      id: "finalComments",
    },
    {
      type: "multicheckbox",
      name: "Support Tags",
      id: "supportTags",
      tags: [
        "Mental Health",
        "Physical Health",
        "Visual Impairment",
        "Learning Disability",
        "English Language Support",
        "Gender",
        "ABP",
      ],
    },
    {
      type: "textboxtabs",
      name: "",
      id: "notes",
      textboxes: [
        {
          name: "Fin-Aid",
          id: "finAid",
        },
        {
          name: "Health",
          id: "health",
        },
        {
          name: "ECA",
          id: "eca",
        },
        {
          name: "Retention",
          id: "retention",
        },
        {
          name: "ABP",
          id: "abp",
        },
        {
          name: "Unable to decide",
          id: "decisionPending",
        },
      ],
    },
  ],
};

function generateStateType(item) {
  if (item.type === "textbox") {
    return "";
  } else if (item.type === "rating") {
    return 0;
  } else if (item.type === "dropdown") {
    return item.menuItems[0];
  } else if (item.type === "multicheckbox") {
    return [];
  }
}

function initalStateValues() {
  let initialState = {
    submitted: false,
  };
  shape.left.forEach(
    (item) => (initialState[item.id] = generateStateType(item))
  );
  shape.right.forEach((item) => {
    if (item.type === "textboxtabs") {
      item.textboxes.forEach((textbox) => {
        initialState[textbox.id] = "N/A";
      });
    } else {
      initialState[item.id] = generateStateType(item);
    }
  });
  return initialState;
}

function ScoringPanel({ currCandidate }) {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState(initalStateValues);
  const [showModal, setShowModal] = React.useState(false);
  const [tab, setTab] = React.useState("1");
  const [readerComments, setReaderComments] = React.useState({});
  const [toastMessage, setToastMessage] = React.useState("");

  const [cookies] = useCookies();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (!currCandidate || !currCandidate.application_id) return;
    axios
      .get("candidates/reader-comments/" + currCandidate.application_id, {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((resp) => {
        console.log("RESP ", JSON.parse(resp.data));
        setReaderComments(JSON.parse(resp.data));
      })
      .catch((err) => {
        setReaderComments({});
      });
  }, [currCandidate]);

  useEffect(() => {
    if (!currCandidate || !currCandidate.id) return;
    axios
      .get(`/candidates/remarks/${currCandidate.id}`, {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((resp) => {
        setValues(resp.data);
      })
      .catch((err) => {
        setValues(initalStateValues());
      });
  }, [currCandidate?.id]);

  function submitForm() {
    setToastMessage("");
    axios
      .post(
        `/candidates/remarks/${currCandidate.id}`,
        {
          ...values,
          submitted: true,
        },
        {
          headers: {
            Authorization: `bearer ${cookies.token}`,
          },
        }
      )
      .then((resp) => {
        setValues({
          ...values,
          submitted: true,
        });
        setToastMessage("Form submitted successfully");
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }

  function editFormValue(key, value) {
    let newValues = { ...values };
    newValues[key] = value;
    setValues(newValues);
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  if (!currCandidate) return;

  return (
    <Styled.Root>
      <Toast message={toastMessage} />
      <Global
        styles={{
          ".swipeable-drawer > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
            width: "75%",
          },
        }}
      />

      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        className="swipeable-drawer"
      >
        <Styled.StyledBox onClick={toggleDrawer(!open)}>
          <Styled.Puller />
          <Typography sx={{ p: 2, color: "white" }}>
            Interview Scoring Panel
          </Typography>
        </Styled.StyledBox>
        <TabContext value={tab}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Scorecard" value="1" />
            <Tab label="Readers Comments" value="2" />
          </TabList>
          <TabPanel value="1" style={{ overflow: "auto" }}>
            <Styled.PanelBody>
              <div style={{ display: "flex" }}>
                <div style={{ width: "30%", height: "100%" }}>
                  {shape.left.map((item) => (
                    <FormBuilder
                      key={item.id}
                      item={item}
                      values={values}
                      editFormValue={editFormValue}
                    />
                  ))}
                </div>
                <div style={{ width: "70%", height: "100%" }}>
                  {shape.right.map((item) => (
                    <FormBuilder
                      key={item.id}
                      item={item}
                      values={values}
                      editFormValue={editFormValue}
                    />
                  ))}
                </div>
              </div>
              <Styled.ButtonCenter>
                <Button variant="contained" onClick={() => setShowModal(true)}>
                  Submit
                </Button>
              </Styled.ButtonCenter>
            </Styled.PanelBody>
          </TabPanel>
          <TabPanel value="2" style={{ overflow: "auto" }}>
            <ReaderComments readerComments={readerComments} />
          </TabPanel>
        </TabContext>
      </SwipeableDrawer>
      <DialogBox
        open={showModal}
        setOpen={setShowModal}
        submitForm={submitForm}
      />
    </Styled.Root>
  );
}

export default ScoringPanel;
