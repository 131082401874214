import { Tab, Tabs, Box } from "@mui/material";
import { useState } from "react";
import QuestionsList from "./QuestionsList";
import FilterTabs from "./Points";
import ResourceSearch from "./ResourceSearch";
import CandidateScoring from "./ScoreCard";

function TabPanel({
  candidateId,
  promptFilters,
  questionnaire = [],
  setQuestionnaire,
  genQuestions = { essay_: [], book_: [], activity_: [] },
  setResourceFiles,
  remarks,
  setRemarks,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderQuestionnaire = () => {
    return (
      <QuestionsList questionnaire={questionnaire} candidateId={candidateId} />
    );
  };

  const renderPoints = () => {
    return (
      <FilterTabs
        promptFilters={promptFilters}
        candidateId={candidateId}
        setQuestionnaire={setQuestionnaire}
        questionnaire={questionnaire}
        genQuestions={genQuestions}
      />
    );
  };

  const renderLookUp = () => {
    return <ResourceSearch setResourceFiles={setResourceFiles} />;
  };

  const renderScoring = () => {
    return (
      <CandidateScoring
        setRemarks={setRemarks}
        remarks={remarks}
        candidateId={candidateId}
      />
    );
  };

  return (
    <Box
      sx={{
        height: "calc(100% - 64px)",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="basic tabs example"
      >
        <Tab label="Look Up" />
        <Tab label="Points" />
        <Tab label="Questionnaire" />
        {/* <Tab label="Remarks" /> */}
      </Tabs>

      {value === 0 && renderLookUp()}
      {value === 1 && renderPoints()}
      {value === 2 && renderQuestionnaire()}
      {/* {value === 3 && renderScoring()} */}
    </Box>
  );
}

export default TabPanel;
