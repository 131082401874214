import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import FormDisplay from './formDisplay';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FieldSettings = ({ field, onChange, fields }) => {
  const handleValueChange = (key, value) => {
    onChange({
      ...field,
      [key]: value
    });
  };

  const handleOptionChange = (optionIndex, key, value) => {
    const newOptions = [...field.options];
    newOptions[optionIndex] = { ...newOptions[optionIndex], [key]: value };
    handleValueChange('options', newOptions);
  };

  const addOption = () => {
    const newOptions = field.options || [];
    newOptions.push({ value: '', label: '' });
    handleValueChange('options', newOptions);
  };

  const deleteOption = (optionIndex) => {
    const newOptions = field.options.filter((_, index) => index !== optionIndex);
    handleValueChange('options', newOptions);
  };

  const handleConditionChange = (key, value) => {
    const newCondition = { ...field.condition, [key]: value };
    handleValueChange('condition', newCondition);
  };

  return (
    <FormGroup>
      <TextField
        label="Label"
        value={field.label}
        onChange={(e) => handleValueChange('label', e.target.value)}
        margin="dense"
      />
      <FormControl fullWidth margin="dense">
        <InputLabel>Type</InputLabel>
        <Select
          value={field.type}
          label="Type"
          onChange={(e) => handleValueChange('type', e.target.value)}
        >
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="textarea">Textarea</MenuItem>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="select">Select</MenuItem>
          <MenuItem value="multiselect">Multiselect</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Checkbox checked={field.required} onChange={(e) => handleValueChange('required', e.target.checked)} />}
        label="Required"
        margin="dense"
      />
      {(field.type === 'select' || field.type === 'multiselect') && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>Options:</Typography>
          {field.options && field.options.map((option, index) => (
            <FormGroup key={index} row>
              <TextField
                label="Value"
                value={option.value}
                onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                sx={{ mr: 1, width: '45%' }}
              />
              <TextField
                label="Label"
                value={option.label}
                onChange={(e) => handleOptionChange(index, 'label', e.target.value)}
                sx={{ width: '45%' }}
              />
              <IconButton onClick={() => deleteOption(index)}><DeleteIcon /></IconButton>
            </FormGroup>
          ))}
          <Button startIcon={<AddCircleOutlineIcon />} onClick={addOption} size="small">Add Option</Button>
        </>
      )}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>Condition:</Typography>
      <FormControl fullWidth margin="dense">
        <InputLabel>Field</InputLabel>
        <Select
          value={field.condition?.field || ''}
          label="Field"
          onChange={(e) => handleConditionChange('field', e.target.value)}
        >
          {fields.map((f, index) => (
            <MenuItem key={index} value={f.id}>{f.label || f.id}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="dense">
        <InputLabel>Condition</InputLabel>
        <Select
          value={field.condition?.type || ''}
          label="Condition"
          onChange={(e) => handleConditionChange('type', e.target.value)}
        >
          <MenuItem value="equalTo">Equal To</MenuItem>
          <MenuItem value="greaterThan">Greater Than</MenuItem>
          <MenuItem value="lesserThan">Lesser Than</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Value"
        value={field.condition?.value || ''}
        onChange={(e) => handleConditionChange('value', e.target.value)}
        margin="dense"
      />
    </FormGroup>
  );
};

const FormCreator = ({ saveRemarks }) => {
  const [fields, setFields] = useState([]);

  const addField = () => {
    const newField = {
      id: `field${fields.length + 1}`,
      type: 'text', // default type
      label: '',
      required: false,
      options: [],
      condition: {
        field: '',
        type: '',
        value: ''
      }
    };
    setFields([...fields, newField]);
  };

  const updateField = (index, newField) => {
    const updatedFields = [...fields];
    updatedFields[index] = newField;
    setFields(updatedFields);
  };

  return (
    <div className='form-creator-container'>
      <div className='form-creator-column'>
        <Button variant="contained" onClick={addField} sx={{ mb: 2 }}>Add Field</Button>
        {fields.map((field, index) => (
          <Accordion style={{ backgroundColor: 'white', color: 'black' }} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Field {index + 1}
            </AccordionSummary>
            <AccordionDetails>
              <FieldSettings field={field} onChange={(updatedField) => updateField(index, updatedField)} fields={fields} />
            </AccordionDetails>
          </Accordion>
        ))}
        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </div>
      <div className='form-display-column'>
        <FormDisplay saveRemarks={saveRemarks} fields={fields} />
        <hr />
      </div>
    </div>
  );
};

export default FormCreator;
