import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  TextField,
  MenuItem,
  Button,
  InputProps,
  Card,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import { genXyUri } from "../../../utils/networkUtils";
import { useCookies } from "react-cookie";
import axios from "axios";

function ResourceSearch({ setResourceFiles }) {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [resources, setResources] = useState([]);
  const [fetchingResources, setFetchingResources] = useState(false);
  const [cookies] = useCookies();
  console.log({
    config,
    resources,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get("/resources/config/", {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((data) => {
        setConfig(data.data[0]); // Assuming the endpoint always returns an array with one object
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch configuration", error);
        setLoading(false);
      });
  }, []);

  const handleFetchResources = () => {
    setFetchingResources(true);
    let descText = description
      ? `description=${encodeURIComponent(description)}`
      : "";
    axios
      .get(`/resources/?title=${encodeURIComponent(title)}&${descText}`, {
        headers: {
          Authorization: `bearer ${cookies.token}`,
        },
      })
      .then((data) => {
        setResources(data.data);
        setFetchingResources(false);
      })
      .catch((error) => {
        console.error("Failed to fetch resources", error);
        setFetchingResources(false);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  const fetchResourcePdf = async (url) => {
    const xyUri = genXyUri(url);
    const response = await axios.get(xyUri, {
      headers: {
        Authorization: `Bearer ${cookies.token}`,
      },
      responseType: "blob",
    });
    const blob = await response.data;
    const blob_url = URL.createObjectURL(blob);
    setResourceFiles((prev) => [...prev, blob_url]);
  };
  console.log("resources ", resources);

  return (
    <div
      style={{
        padding: "20px",
        height: "calc(100% - 48px)",
        overflow: "scroll",
      }}
    >
      <Autocomplete
        disablePortal
        options={config?.unique_titles || []}
        sx={{ width: 300, marginBottom: 2 }}
        renderInput={(params) => (
          <TextField
            InputProps={{ ...params.InputProps, style: { color: "black" } }}
            {...params}
            label="Title"
          />
        )}
        onChange={(event, newValue) => {
          setTitle(newValue);
        }}
      />
      <Autocomplete
        disablePortal
        options={config?.unique_descriptions || []}
        sx={{ width: 300, marginBottom: 2 }}
        renderInput={(params) => (
          <TextField
            InputProps={{ ...params.InputProps, style: { color: "black" } }}
            {...params}
            label="Description"
          />
        )}
        onChange={(event, newValue) => setDescription(newValue)}
      />
      <Button
        variant="contained"
        onClick={handleFetchResources}
        disabled={!title}
        startIcon={fetchingResources ? <CircularProgress size={20} /> : null}
      >
        Fetch Resources
      </Button>
      {resources.map((resource) => (
        <Card
          onClick={() => fetchResourcePdf(_.get(resource, "link"))}
          key={resource.link}
          variant="outlined"
          style={{ padding: "10px", margin: "10px 0px", cursor: "pointer" }}
        >
          <p>Title: {resource.title}</p>
          <p>Description: {resource.description}</p>
          <p>
            Link:{" "}
            <a target="_blank" href={resource.link}>
              {resource.link}
            </a>
          </p>
          {/*<p>File Type: {resource.file_type}</p> */}
          <p>Tags: {resource.tags}</p>
        </Card>
      ))}
    </div>
  );
}

export default ResourceSearch;
