import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

function RenderTags({ tags }) {
  const tagElements = Object.entries(tags)
    .filter(([key, value]) => value === true)
    .map(([key], index) => (
      <Chip key={index} label={key} variant="filled" color="primary" />
    ));

  return (
    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
      {tagElements}
    </Stack>
  );
}

export default RenderTags;
