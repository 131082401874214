import React from 'react';
import { Typography } from '@mui/material';
import FormCreator from './formCreator';

const FormConfiguration = () => {
  return (
    <>
    <Typography variant="h4" gutterBottom>
      Form Configuration
    </Typography>
    <FormCreator />
    </>

  );
};

export default FormConfiguration;
