import styled from "styled-components";

export const Heading = styled.h1`
  font-weight: 800;
  font-size: 2.4rem;
  color: #140d1c;
  margin: 0.2rem;
`;

export const Subheading = styled.h3`
  color: #140d1c;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
`;

export const Input = styled.input`
  width: 260px;
  padding: 15px;
  margin-top: 7px;
  background-color: #ede8f2;
  border: 0;
  border-radius: 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputBox = styled.div`
  margin: 10px;
`;

export const InputTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;

export const ForgotPassword = styled.p`
  margin: 10px;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
