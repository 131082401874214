import styled from "styled-components";

export const Root = styled.div`
  height: 100%;
  background-color: grey;
`;

export const StyledBox = styled.div`
  background-color: #1876d2;
  position: absolute;
  top: -56px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  visibility: visible;
  right: 0px;
  left: 0px;
  pointer-events: all;
`;

export const Puller = styled.div`
  width: 30px;
  height: 6px;
  background-color: white;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
`;

export const PanelBody = styled.div`
  padding: 10px;
  height: 100%;
  overflow: auto;
  background-color: white;
  color: black;
`;

export const RatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

export const TextBoxWrapper = styled.div`
  padding: 15px;
`;

export const ButtonCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
